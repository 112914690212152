import React from 'react'

import styled from 'styled-components'
const Wrapper = styled.section`
  width: 100%;
  height: ${ p => p.height};
  display: flex;
  margin: 5rem 0;

  iframe {
    width: 95%;
    height: 100%;
    flex: 1;
  }
`

const IFrame = ({title, src, height='100%'}) => {
  return (
      <Wrapper height={height}>
        <iframe
          title={title}
          src={src}
          // allowtransparency = "true"
        >
          {/* 載入中… */}
        </iframe>
      </Wrapper>
  )
}

export default IFrame
