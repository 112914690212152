import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from "components/Shared/_PageContent"
import IFrame from 'components/Shared/IFrame'

import ShopNav from "components/member/ShopNav"


const Dress = ({data, location}) => {
  const { seo } = data
  const { title, description } = seo.frontmatter.dress

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <ShopNav location={location} />
      
      <IFrame
        title='Dress'
        src='https://goo.gl/forms/J2r7jkRa7UBz0zOV2'
        height='80vh'
      >
        載入中…
      </IFrame>
    </PageContent>
  )
}

export default Dress

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
      frontmatter {
        dress {
          title
          description
        }
      }
    }
  }
`